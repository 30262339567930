.listi-aðal {
  width: 100%;
  height: fit-content;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: transparent;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

::-webkit-scrollbar {
  width: 0.3rem;
}


.listi-leppakassi-mót1 {
  width: 85em;
  margin: 5em 0;
  height: fit-content;
}

.listi-leppakassi-mót2 {
  width: 80em;
  font-size: 0.54em;
  margin: 5em 4em;
  height: fit-content;
}

.listi-leppakassi-mót3 {
  width: 80em;
  font-size: 0.36em;
  margin: 8em 0;
  height: fit-content;
}

.listi-leppakassi-lið1 {
  width: 85em;
  margin: 6em 0;
  height: fit-content;
}

.listi-leppakassi-lið2 {
  width: 80em;
  font-size: 0.54em;
  margin: 6em 0;
  height: fit-content;
}

.listi-leppakassi-lið3 {
  width: 80em;
  font-size: 0.36em;
  margin: 8em 0;
  height: fit-content;
}


.listi-leppakassi-gutlarar1 {
  width: 80%;
  margin: 10em 0;
  font-size: 0.8em;
  height: fit-content;
}

.listi-leppakassi-gutlarar2 {
  width: 35%;
  font-size: 0.50em;
  margin: 15em 0;
  /* height: 135em; */
  height: fit-content;
}

.listi-leppakassi-gutlarar3 {
  width: 25%;
  font-size: 0.36em;
  margin: 8em 3%;
  height: fit-content;
}

.listi-leppakassi-leikur1 {
  width: 85em;
  margin: 5em 0;
  height: fit-content;
}

.listi-leppakassi-leikur2 {
  width: 82em;
  font-size: 0.54em;
  margin: 5em 4em;
  height: fit-content;
}

.listi-leppakassi-leikur3 {
  width: 80em;
  font-size: 0.36em;
  margin: 8em 0;
  height: fit-content;
}

.listi-leppakassi-mótsbón1 {
  width: 85em;
  margin: 5em 0;
  height: fit-content;
}

.listi-leppakassi-mótsbón2 {
  width: 80em;
  font-size: 0.54em;
  margin: 5em 4em;
  height: fit-content;
}

.listi-leppakassi-mótsbón3 {
  width: 80em;
  font-size: 0.36em;
  margin: 8em 0;
  height: fit-content;
}

.listi-leppakassi-bjóðaliði1 {
  width: 85em;
  margin: 6em 0;
  height: fit-content;
}

.listi-leppakassi-bjóðaliði2 {
  width: 80em;
  font-size: 0.54em;
  margin: 6em 0;
  height: fit-content;
}

.listi-leppakassi-bjóðaliði3 {
  width: 80em;
  font-size: 0.36em;
  margin: 8em 0;
  height: fit-content;
}

.listi-leppakassi-liðgögn1 {
  width: 85em;
  margin: 6em 0;
  height: fit-content;
}

.listi-leppakassi-liðgögn2 {
  width: 80em;
  font-size: 0.54em;
  margin: 6em 0;
  height: fit-content;
}

.listi-leppakassi-liðgögn3 {
  width: 80em;
  font-size: 0.36em;
  margin: 8em 0;
  height: fit-content;
}

.listi-leppakassi-vellir1 {
  width: 85em;
  margin: 5em 0;
  height: fit-content;
}

.listi-leppakassi-vellir2 {
  width: 90em;
  font-size: 0.5em;
  margin: 5em 4em;
  height: fit-content;
}

.listi-leppakassi-vellir3 {
  width: 90em;
  font-size: 0.32em;
  margin: 8em 0;
  height: fit-content;
}

@media (max-aspect-ratio: 6/5) {

  .listi-leppakassi-leikur3 {
    font-size: 0.56em;
  }

  .listi-leppakassi-vellir3 {
    font-size: 0.48em;
  }
}
