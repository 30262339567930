.takki1 {
  width: 100%;
  height: 100%;
  text-decoration: none;
  border: none;
  font-size: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0.2em;
  transition-duration: 0.4s;
  background-color: rgba(0, 0, 0, .25);
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);
}

.takki1:hover {
  transform: scale(1.1);
}

.takki1:active {
  background-color: black;
}

.takki1litur {
  width: 90%;
  height: 90%;
  border: none;
  background-color: transparent;
  box-shadow: 0 0 1rem 0 rgba(0,0,0, .4);
  text-decoration: none;
  font-size: 0.9em;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition-duration: 0.4s;
}

.takki1litur:hover {
  transform: scale(1.1);
}

.takki1litur:active {
  background-color: black;
}

.takki1einginnbakgrunnur {
  width: 110%;
  height: 110%;
  background-color: transparent;
  border: none;
  text-decoration: none;
  font-size: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition-duration: 0.4s;
}

.takki1einginnbakgrunnur:hover {
  scale: 1.1;
}

.takki1einginnbakgrunnur:active {
  color: black;
}