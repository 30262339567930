.hhheim-aðal {
  width: 20%;
}

.hhheim-takkakassi {
  width: 60%;
  font-size: 2em;
  margin-top: 1em;
}


@media (max-aspect-ratio: 6/5) {
  .hhheim-aðal {
    width: 100%;
    margin-bottom: 8rem;
  }
  
  .hhheim-takkakassi {
    font-size: 8rem;
  }

}

