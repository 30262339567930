
.vellir-heimtakki {
  font-size: 1.5rem;
  position: absolute;
  left: 0;
  top: 0;
}

@media (max-aspect-ratio: 6/5) {
  .vellir-heimtakki {
    font-size: 2.5rem;
  }
}

@media (max-aspect-ratio: 8/10) {
  .vellir-heimtakki {
    font-size: 5.5rem;
  }
}



