.smíðagutlara-aðal {
  width: 90rem;
}

.smíðagutlara-firirsögn {
  margin: 1rem;
  font-size: 3.5rem;
}

.smíðagutlara-tilkvers {
  margin: 0.6em;
  font-size: 1.8rem;
}

.smíðagutlara-tilkvers-texti {
  margin: 0.3em;
  font-size: 1.5rem;
  text-align: center;
  width: 80%;
}

.smíðagutlara-inntök {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.smíðagutlara-inntak-lesmál {
  width: 45%;
  font-size: 0.5rem;
  margin: 1.5rem 0;
}

.smíðagutlara-veljakin {
  font-size: 0.4rem;
}

.smíðagutlara-fæðíngarár {
  display: flex;
  justify-content: space-between;
}

.smíðagutlara-fæðíngarár button {
  font-size: 1.7rem;
  width: 35%;
}

.smíðagutlara-veljafæðíngarártakki {
  width: 45%;
  font-size: 3rem;
  margin: 4rem 0;
}

.smíðagutlara-inntakmind {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.smíðagutlara-innsendamind {
  /* font-family: Georgia, 'Times New Roman', Times, serif; */
  text-decoration: none;
  border: none;
  font-size: 3rem;
  width: 45%;
  text-align: center;
  padding: 0.1rem;
  background-color: rgba(0, 0, 0, .25);
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);
}

.smíðagutlara-mind {
  width: 35%;
  margin: 0 5%;
  cursor: pointer;
  transition-duration: 0.4s;
  border-radius: 100%;
}

.smíðagutlara-mind:hover {
  filter: invert(90%);
}

.smíðagutlara-staðfestaval {
  width: 100%;
  margin-top: 2rem;
}

.smíðagutlara-smíðagutlara-takki {
  font-size: 4.4rem;
  margin: 0 0 4rem 0;
}

.smíðagutlara-smíðagutlara-takki-minni {
  font-size: 2rem;
  width: fit-content;
  margin: 1rem 0;
}


@media (max-aspect-ratio: 6/5) {
  .smíðagutlara-firirsögn {
    margin: 0.5rem;
    font-size: 5.5rem;
  }

  .smíðagutlara-tilkvers {
    font-size: 3.2rem;
  }
  
  .smíðagutlara-tilkvers-texti {
    font-size: 4rem;
  }

  .smíðagutlara-inntök {
    flex-direction: column;
  }

  .smíðagutlara-inntak-lesmál {
    width: 80%;
    font-size: 0.7rem;
    margin: 3rem;
  }

  .smíðagutlara-veljafæðíngarártakki {
    width: 75%;
    font-size: 4rem;
    margin: 5rem 0;
  }

  .smíðagutlara-fæðíngarár button {
    font-size: 3.5rem;
    width: 35%;
  }

  .smíðagutlara-innsendamind {
    font-size: 5.5rem;
    width: 80%;
    margin: 2rem 0;
  }

  .smíðagutlara-staðfestaval {
    width: 75%;
    margin-top: 4rem;
  }

  .smíðagutlara-smíðagutlara-takki {
    font-size: 5.4rem;
    margin: 4rem 0;
  }

  .smíðagutlara-smíðagutlara-takki-minni {
    font-size: 3.5rem;
  }
}