.svaraboði-grunnur {
  display: flex;
  position: relative;
  min-height: 100vh;
  width: 100%;
}

.svaraboði-umgjörð {
  width: 90%;
}
