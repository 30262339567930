.veljagutlara-listi {
  font-size: 36em;
  height: calc(100vh - 0.3em);
  /*min-height: 0.9em; */
}

@media (max-aspect-ratio: 6/5) {
  .veljagutlara-listi {
    font-size: 50em;
    height: calc(100dvh - 0.3em);
  }

}