.breitaeiginl-aðal {
  font-size: 1em;
}

.breitaeiginl-þarfarupplísíngar {
  margin-bottom: 1.5em;
  font-size: 1.5em;
  text-align: center;
}

.breitaeiginl-hættaviðbreitíngar {
  font-size: 1.7em;
  margin: 0.8em;
}

.breitaeiginl-eiginleiki {
  width: 100em;
  font-size: 0.3em;
  margin-bottom: 10em;
}

.breitaeiginl-velja {
  font-size: 2em;
  margin-bottom: 1.5em;
}

.breitaeiginl-flotter {
  font-size: 3em;
}


.breitaeiginl-varavöllur-umgjörð {
  width: 30em;
  margin-bottom: 3em;
}

.breitaeiginl-varavöllur-takkar {
  display: flex;
}

.breitaeiginl-varavöllur-takki {
  font-size: 1.4em;
  margin-right: 1em;
}

.breitaeiginl-varavöllur-texti {
  font-size: 2em;
  margin-bottom: 1em;
}

.breitaeiginl-staðfestaval {
  font-size: 3em;
  margin-top: 0.6em;
  margin-bottom: 2em;
  max-width: 90%;
}

.breitaeiginl-veljaleikstefnilisti {
  font-size: 48em;
  height: calc(100vh - 0.09em);
}
/*Athuga þetta þegar ég breiti útliti á breita eiginleikum liðs
.breitaeiginl-veriðaðbreitaeiginleikum {
  font-size: 3em;
  margin: 2em;
}
*/

@media (max-aspect-ratio: 6/5) {
  .breitaeiginl-aðal {
    font-size: 1.7em;
  }

  .breitaeiginl-veljaleikstefnilisti {
    font-size: 65em;
  }

  .breitaeiginl-leikstefnir-auka {
    font-size: 1em;
  }

}

@media (max-aspect-ratio: 1) {
  .breitaeiginl-aðal {
    font-size: 2em;
  }

  .breitaeiginl-leikstefnir-auka-titill {
    font-size: 2.4em;
  }

}