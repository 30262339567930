.spjallárn-aðal {
  width: 90%;
  height: 70vh;
  min-height: 32em;
  margin: 2em;
}

.spjallárn-inntakskassi {
  height: 14em;
  width: 95%;
  font-size: 1em;
  color: black;
  display: flex;
  padding-bottom: 0.6em;

}

.spjallárn-inntak {
  text-shadow: none;
  color: white;
  border-radius: 0;
  border: none;
  padding-left: 0.2em;
  font-size: 2em;
  background-color: rgba(0, 0, 0, 0.5);
  font-family: 'Times New Roman', Times, serif;
  height: 3.5em;
  width: 80%;
}

.spjallárn-inntak:focus {
  outline: none;
}

.spjallárn-senda {
  font-size: 2.5em;
  height: fit-content;
  width: fit-content;
  margin-left: 0.8em;
}

.spjallárn-spjallkassi {
  font-size: 1.5em;
}

@media (max-aspect-ratio: 6/5) {
  .spjallárn-aðal {
    font-size: 1.5em;
  }

}