.gutlarasmeija-aðal {
  width: 100%;
  overflow-x: hidden;
  font-size: 1.6rem;
}

.gutlarasmeija-mindafgutlara {
  max-width: 40em;
  min-width: 20em;
  margin: 2em;
  max-height: 36em;
  border-radius: 100%;
}

.gutlarasmeija-nafn {
  font-size: 3.6em;
  margin-top: 0.2em;
  text-align: center;
}

.gutlarasmeija-gælunafn {
  font-size: 2em;
  margin-top: 0.5em;
}

.gutlarasmeija-leikjanautaskilaboð {
  font-size: 1.3em;
  margin-top: 1em;
}

/* @media (max-aspect-ratio: 17/10) {
  .gutlarasmeija-aðal {
  }

} */

@media (max-aspect-ratio: 5/5) {
  .gutlarasmeija-aðal {
    font-size: 2rem;
  }

}

@media (max-aspect-ratio: 4/5) {
  .gutlarasmeija-aðal {
    font-size: 2.4rem;
  }

}

@media (max-aspect-ratio: 3/5) {
  .gutlarasmeija-aðal {
    font-size: 2.8rem;
  }

  .gutlarasmeija-nafn {
    margin-top: 0.5em;
  }

}