.leppurvöllur-aðal {
  height: fit-content;
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  background-color: rgba(255, 255, 255, .15);
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);
}

.leppurvöllur-nafnogmynd {
  /* display: flex;
  flex-direction: column;
  align-items: center;
  transition-duration: 0.4s;
  cursor: pointer; */
  margin-bottom: 0.5rem;
}

.leppurvöllur-nafnogmynd:hover {
  scale: 1.05;
}

.leppurvöllur-nafnvallar {
  font-size: 8em;
  margin: 0.3em;
}

.leppurvöllur-aukanafn {
  font-size: 7em;
  margin: 0.3em;
}

.leppurvöllur-myndafvelli {
  width: 80em;
  border-radius: 2em;
}

.leppurvöllur-takkakassi {
  font-size: 8em;
  margin-bottom: 0.5em;
}

@media (max-width: 900px) {
  .leppurvöllur-nafnvallar {
    font-size: 10em;
    /* margin: 0.3em; */
  }

  .leppurvöllur-takkakassi {
    font-size: 13em;
    /* margin-bottom: 0.5em; */
  }

}