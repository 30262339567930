.vallarbleðill-aðal {
  height: 100%;
  justify-content: space-around;
}

.vallarbleðill-nafnvallar {
  font-size: 10em;
  cursor: pointer;
}

.vallarbleðill-aukanafn {
  font-size: 6em;
  cursor: pointer;
}

.vallarbleðill-myndafvelli {
  width: 76em;
  border-radius: 2em;
  cursor: pointer;
}