
.innskráníng-umgjörð {
  position: relative;
  min-height: 100vh;
  width: 100%;
}

.innskráníng-umgjörð-takkar {
  justify-content: flex-end;
  width: 90%;
}

.innskráníng-umgjörð-takki {
  font-size: 2.2rem;
  margin: 1rem;
}

@media (max-aspect-ratio: 6/5) {
  

  .innskráníng-umgjörð-takki {
    font-size: 4.5rem;
    margin: 0.7em 0.8em;
  }
}