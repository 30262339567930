.heim-aðal {
  display: flex;
  overflow-x: hidden;
}


@media (max-aspect-ratio: 6/5) {
  .heim-aðal {
    flex-direction: column;
  }
}