.leikurtímaskrá-aðal {
  width: 100%;
}

.leikurtímaskrá-firirsögn {
  font-size: 4em;
  margin: 0.2em;
  text-align: center;
}

.leikurtímaskrá-tími {
  font-size: 4em;
  margin: 0.2em;
  max-width: 90%;
  text-align: center;
}

.leikurtímaskrá-gögn {
  font-size: 3em;
  margin: 0.2em;
  text-align: center;
}

.leikurtímaskrá-leikstefnir {
  cursor: pointer;
  transition-duration: 0.4s;
}

.leikurtímaskrá-leikstefnir:hover {
  font-size: 1.1em;
}

.leikurtímaskrá-greiðslatakki {
  width: fit-content;
  margin: 0.5em 0 0 0;
}

.leikurtímaskrá-takkar {
  margin: 1em;
  max-width: 95%;
}

.leikurtímaskrá-takkar button {
  font-size: 2em;
  margin: 0.5em;
  height: fit-content;
  width: fit-content;
}

.leikurtímaskrá-takkar p {
  font-size: 3em;
  margin: 0.5em;
}

.leikurtímaskrá-takkar-umgjörð {
  display: flex;
}

@media (max-aspect-ratio: 6/5) {
  .leikurtímaskrá-tími {
    font-size: 5em;
  }

  .leikurtímaskrá-gögn {
    font-size: 3.8em;
  }

  .leikurtímaskrá-takkar button {
    font-size: 4em;
  }

  .leikurtímaskrá-takkar-umgjörð {
    flex-direction: column;
    align-items: center;
  }

}