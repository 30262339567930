.vinstribtnl-aðal {
  width: 40%;
  min-height: 100vh;
  font-size: 1rem;

}

.vinstribtnl-firirsögn {
  margin-top: 0.5em;
  width: 100%;
  text-align: center;
  font-size: 3em;
}

.vinstribtnl-völlurtími {
  justify-content: space-around;
  height: 100%;
  width: 100%;
}

.vinstribtnl-völlur {
  height: 28em;
  width: 100%;
  position: relative;
}

.vinstribtnl-völlur-kassi {
  height: 100%;
  font-size: 0.39em;
}

.vinstribtnl-breitaum-takka-kassi {
  position: absolute;
  top: 0;
  left: 0.5em;
  font-size: 1.5em;
}

.vinstribtnl-takkakassi {
  font-size: 3em;
}

.vinstribtnl-tími {
  height: 12em;
  position: relative;
  width: 100%;
  margin-top: 1.5em;
}

.vinstribtnl-tími-dagsetníng {
  font-size: 2.3em;
  margin-top: 1.2em;
  margin-bottom: 0.4em;
}

.vinstribtnl-tími-tími {
  font-size: 2.8em;
}

.vinstribtnl-staðurleiks {
  width: 100%;
  height: 100%;
  font-size: 6.5em;
  text-align: center;
}

.vinstribtnl-breita-umgjörð {
  height: 100%;
  width: 100%;
}

.vinstribtnl-breita-umgjörð button {
  margin: 1em 0;
  font-size: 4em;
  width: fit-content;
  height: fit-content;
}

@media (max-aspect-ratio: 6/5) {
  .vinstribtnl-aðal {
    width: 100%;
    min-height: auto;
    font-size: 2.5rem;
  }

  .vinstribtnl-firirsögn {
    margin-bottom: 1em;
  }
}