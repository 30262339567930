.smeija-smeijugrunnur {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10;
}

.smeija-smeija {
  width: 70rem;
  margin-top: 3vh;
  height: 95vh;
  background-image: linear-gradient(120deg, #b1d8f7, #1e3468);
  overflow-y: auto;
  border-radius: 0.4rem;
  font-size: 1rem;
  position: relative;
}

.smeija-lokasmeiju {
  position: fixed;
  top: 3vh;
  right: 14.5rem;
  background-color: red;
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  font-size: 1.5rem;
}

.smeija-lokasmeiju:hover {
  background-color: rgb(201, 0, 0);
}


@media (max-aspect-ratio: 6/5) {

  .smeija-smeijugrunnur {
    min-height: 100dvh;
  }

  .smeija-smeija {
    width: 99rem;
    margin-top: 0.8dvh;
    height: 99dvh;
    font-size: 1.4rem;
  }
  
  .smeija-lokasmeiju {
    top: 0.5vh;
    height: 6rem;
    right: 1.5rem;
    width: 6rem;
    font-size: 5.5rem;
  }
}