.hahúm-aðal {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;    
  font-size: 7em;

}

.hahúm-lesmálvals {
  margin: 0.2em 0 0 0.2em;
}

.hahúm-inntakskassi {
  cursor: pointer;
  display: flex;
  margin: 0.5em 0;
  align-items: center;
}

.hahúm-lesmál {
  margin-left: 0.4em;
  font-size: 0.9em;
}

.hahúm-inntak {
  height: 0;
  width: 0;
  opacity: 0;
  position: absolute;
  cursor: pointer;
}

.hahúm-hak {
  margin-left: 0.4em;
  height: 1.6em;
  width: 1.6em;
  background-color: rgba(255, 255, 255, 0.3);
}

.hahúm-inntak:checked~.hahúm-hak {
  background-color: rgba(0, 0, 0, 0.4);
}

.hahúm-hak:after {
  content: "";
  position: relative;
  top: 0.16em;
  left: 0.45em;
  width: 0.45em;
  height: 0.85em;
  border: solid white;
  border-width: 0 0.25em 0.25em 0;
  display: none;
  transform: rotate(40deg);
}

.hahúm-inntak:checked~.hahúm-hak:after {
  display: block;
}