.leikjanautar-aðal {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  position: relative;
}


.leikjanautar-firirsögn {
  font-size: 3em;
}

.leikjanautar-minni-firirsögn {
  font-size: 0.3em;
}

.leikjanautar-takkar {
  display: none;
}

.leikjanautar-leikjanautalistar {
  display: flex;
  width: 90%;
  justify-content: space-between;
  margin-top: 2em;
}

.leikjanautar-listi {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
}

.leikjanautar-listi-firirsögn {
  font-size: 2em;
  margin: 0 0 0.4em 0;
}

.leikjanautar-listi-listi {
  font-size: 28em;
  height: calc(100vh - 0.35em);
  min-height: 0.9em;
}

.leikjanautar-heimtakki {
  font-size: 1.5rem;
  position: absolute;
  left: 0;
  top: 0;
}

@media (max-aspect-ratio: 7/5) {
  .leikjanautar-heimtakki {
    font-size: 2.5rem;
  }
}

@media (max-aspect-ratio: 5/5) {
  .leikjanautar-firirsögn {
    font-size: 10em;
  }
  
  .leikjanautar-leikjanautalistar {
    width: 100%;
    flex-direction: column;
  }

  .leikjanautar-listi-firirsögn {
    font-size: 6em;
    margin-top: 0.8em;
  }
  
  .leikjanautar-listi-listi {
    font-size: 85em;
    height: 90vh;
  }

  .leikjanautar-takkar {
    font-size: 3em;
    margin: 1.5em 0 0.5em 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
  }

  .leikjanautar-takkar button {
    font-size: 1.35em;
    width: fit-content;
  }

  .leikjanautar-takkar button:hover {
    transform: scale(1);
  }

  .leikjanautar-heimtakki {
    font-size: 5.5rem;
  }

}
