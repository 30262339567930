.leppurleikur-leikur {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition-duration: 0.4s;
  text-decoration: none;
  background-color: rgba(255, 255, 255, .15);
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);
}

.leppurleikur-ítáleik {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition-duration: 0.4s;
  text-decoration: none;
  width: 100%;
}

.leppurleikur-ítáleik:hover {
  scale: 1.1;
}



.leppurleikur-völlur-leiks {
  font-size: 9em;
  text-align: center;
  margin-top: 0.3em;
}

.leppurleikur-dagsetníng-leiks {
  font-size: 7em;
  margin-top: 0.5em;
  width: 95%;
  text-align: center;
}

.leppurleikur-tími-leiks {
  font-size: 8em;
  margin-top: 0.4em;
}

.leppurleikur-ámóti {
  font-size: 6.5em;
  margin-top: 0.3em;
}

.leppurleikur-gutlarareðalið-leiks {
  font-size: 6em;
  margin-top: 0.5em;
  margin-bottom: 0.3em;
}

.leppurleikur-memma-leik-kassi {
  width: 70%;
  font-size: 8em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.leppurleikur-takkakassi {
  font-size: 8em;
  width: 9.5em;
  margin: 0.3em 0;
}

.leppurleikur-svar-miðlara {
  font-size: 7em;
  margin: 0.5em 0;
  max-width: 80%;
  text-align: center;
  background-color: rgba(39, 80, 107, 0.3);
  border-radius: 5%;
  padding: 0.3em;
}

.leppurleikur-strik {
  box-shadow: 0 0 1em 0.4em rgba(0, 0, 0, .2);
  width: 60em;
  background-color: transparent;
  border: none;
  margin-top: 2em;
}