.veljaeiginleiks-umgjörð {
  font-size: 1rem;
  width:100%;
}

.veljaeiginleiks-firirsögn-smeiju {
  font-size: 3em;
  margin: 0.3em 0;
  max-width: 90%;
  text-align: center;
}

.veljaeiginleiks-kassi {
  width: 43%;
  font-size: 0.3em;
  margin-bottom: 10em;
}

.veljaeiginleiks-velja {
  font-size: 2.1em;
  margin-bottom: 1.5em;
}

.veljaeiginleiks-varavöllur-texti {
  font-size: 2em;
  margin-bottom: 0.5em;
  text-align: center;
}

.veljaeiginleiks-varavöllur-takkar {
  display: flex;
  justify-content: space-around;
}

.veljaeiginleiks-varavöllur-takkakassi {
  max-width: 40%;
  font-size: 1.4em;
  margin-bottom: 2em;
}

.veljaeiginleiks-lið-skjaldamerki {
  width: 16em;
  border-radius: 100%;
}

.veljaeiginleiks-staðfestaval-kassi {
  font-size: 3em;
  margin-bottom: 1em;
}

@media (max-aspect-ratio: 6/5) {
  .veljaeiginleiks-umgjörð {
    font-size: 1.9rem;
  }

  .veljaeiginleiks-kassi {
    width: 70%;
  }

}

@media (max-aspect-ratio: 4/5) {
  .veljaeiginleiks-umgjörð {
    font-size: 2.4rem;
  }

  .veljaeiginleiks-kassi {
    width: 90%;
  }

}

