.almenn-grunnur {
  display: flex;
  position: relative;
  min-height: 100vh;
  width: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.almenn-óveljanlegurtexti {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.almenn-heimtakki {
  font-size: 1.5rem;
  position: absolute;
  left: 0;
  top: 0;
}

.almenn-smeija-aðal {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-size: 1rem;
}

.almenn-smeija-firirsögn {
  font-size: 2.8rem;
  margin: 1rem;
  margin-bottom: 1.6rem;
}

.umgjörð-lárétt {
  display: flex;
  align-items: center;
  justify-content: center;
}

.umgjörð-lárétt-ómiðjað {
  display: flex;
  align-items: center;
}

.umgjörð-lóðrétt {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.umgjörð-lóðrétt-ómiðjað {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.grunn-smeija {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.smellanlegur {
  cursor: pointer;
  transition-duration: 0.4s;
}

.smellanlegur:hover {
  scale: 1.1;
}

.smeija-firirsögn {
  font-size: 4em;
  margin: 0.5em 0;
  text-align: center;
  max-width: 95%;
}

.smeija-aðaltakki {
  font-size: 3em;
  margin: 0.5em 0;
  max-width: 90%;
  text-align: center;
}

.smeija-takki {
  font-size: 2em;
  margin: 0.5em 0;
  max-width: 90%;
  text-align: center;
}

.smeija-lítilltakki {
  font-size: 1.5em;
  margin: 0.5em 0;
  max-width: 90%;
  text-align: center;
}

.smeija-listi {
  width: 65rem;
  font-size: 0.65rem;
}

.smeija-flettilisti {
  font-size: 65rem;
}

.texti {
  font-size: 2rem;
  width: 90%;
  margin: 3rem 0;
  padding: 2rem;
  background-color: rgba(0, 0, 0, .25);
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);
  border-radius: 1.6rem;
}

.spurn {
  border-radius: 100%;
  border: 0.06em solid black;
  font-size: 0.7em;
  padding: 0 0.2em;
}

.spurn-rammi {
  background-color: rgba(0, 0, 0, .25);
  box-shadow: 0 0 1em 0 rgba(0, 0, 0, .2);
  border-radius: 1.6em;
  padding: 0.5em;
  max-width: 90%;
}

.tveir-takkar {
  justify-content: space-around;
  width: 80%;
}


@media (max-aspect-ratio: 6/5) {

  .almenn-heimtakki {
    font-size: 5.5rem;
  }

  .smeija-firirsögn {
    font-size: 6em;
  }
  
  .smeija-aðaltakki {
    font-size: 5em;
  }
  
  .smeija-takki {
    font-size: 3em;
  }

  .smeija-listi {
    width: 85rem;
    font-size: 0.85rem;
  }

  .smeija-flettilisti {
    font-size: 90rem;
  }

  .smeija-lítilltakki {
    font-size: 2.5em;
  }

  .texti {
    font-size: 5rem;
  }

}