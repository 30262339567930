.villuhleðurtakki-umgjörð {
  font-size: 1em;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.villuhleðurtakki-umgjörð button {
  width: fit-content;
  max-width: 100%;
}

.villuhleðurtakki-texti {
  width: fit-content;
  max-width: 100%;
  text-align: center;
}

.villuhleðurtakki-villa {
  margin-bottom: 0.3em;
}
