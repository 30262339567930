.útftexti-aðal {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  font-size: 7em;
}

.útftexti-lesmál {
  text-decoration: none;
  margin-bottom: 0.2em;
}

.útftexti-inntak {
  text-shadow: none;
  color: white;
  border-radius: 0;
  border: none;
  height: 50%;
  padding-left: 0.2em;
  font-size: 1.1em;
  background-color: rgba(0,0,0,0.5);
  font-family: 'Times New Roman', Times, serif;
}

.útftexti-inntak:focus {
  outline: none;
}

.útftexti-inntak-stærra {
  height: 100%;
}
