
.ivirgefaleik-umgjörð {
  height: 100%;
  width: 90%;
}
 

.ivirgefaleik-bóntakki {
  font-size: 3em;
  width: fit-content;
  max-width: 90%;
  margin: 4vh 0;
}

.ivirgefaleik-valinnleikstefnir-titill {
  font-size: 3em;
  margin-top: 0.7em;
}

.ivirgefaleik-leiksstefnisskjöldur {
  width: 18em;
  margin: 1.5em 0 3em 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, .15);
  box-shadow: 0 0 1em 0 rgba(0, 0, 0, .2);
  border-radius: 5%;
}

.ivirgefaleik-leiksstefnisskjöldur button {
  font-size: 1.4em;
  margin: 0.5em;
  width: 80%;
}

.ivirgefaleik-leiksstefnismind {
  width: 65%;
  border-radius: 100%;
  margin: 0.5em 0;
}

.ivirgefaleik-leiksstefnisnafn {
  font-size: 1.8em;
  text-align: center;
}

@media (max-aspect-ratio: 1) {
  .ivirgefaleik-bóntakki {
    font-size: 5em;
  }

  .ivirgefaleik-valinnleikstefnir-titill {
    font-size: 5em;
  }
  
  .ivirgefaleik-leiksstefnisskjöldur {
    font-size: 2em;
  }

}