.flettíngarlisti-aðal {
  width: 1em;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flettíngarlisti-leit-umgjörð {
  width: 90em;
  font-size: 0.008em;
}

.flettíngarlisti-leit-umgjörð-1 {
  width: 100em;
  font-size: 0.006em;
}

.flettíngarlisti-leit-umgjörð-2 {
  width: 110em;
  font-size: 0.004em;
}

.flettíngarlisti-leit-takki {
  font-size: 0.06em;
  width: 4em;
  margin: 0.3em;
} 

.flettíngarlisti-leit-takki-1 {
  font-size: 0.045em;
  width: 4em;
  margin: 0.3em;
} 

.flettíngarlisti-leit-takki-2 {
  font-size: 0.03em;
  width: 4em;
  margin: 0.3em;
} 

.flettíngarlisti-skilaboðmiðlara {
  font-size: 0.06em;
  text-align: center;
  margin: 1em 0;
  max-width: 90%;
}

.flettíngarlisti-listi {
  width: 100em;
  font-size: 0.01em;
  height: 100%;
}

.flettíngarlisti-listi-aukaleitogtakkar {
  height: calc(100% - 32em);
}

.flettíngarlisti-listi-aukaleitogtakkar-1 {
  height: calc(100% - 23em);
}

.flettíngarlisti-listi-aukaleitogtakkar-2 {
  height: calc(100% - 15em);
}

.flettíngarlisti-listi-aukatakkar {
  height: calc(100% - 12em);
}

.flettíngarlisti-listi-aukatakkar-1 {
  height: calc(100% - 9em);
}

.flettíngarlisti-listi-aukatakkar-2 {
  height: calc(100% - 6em);
}

.flettíngarlisti-listi-aukaleit {
  height: calc(100% - 21em);
}

.flettíngarlisti-listi-aukaleit-1 {
  height: calc(100% - 15em);
}

.flettíngarlisti-listi-aukaleit-2 {
  height: calc(100% - 10em);
}

/*ætti að nota það sameiginlega í þessu*/

.flettíngarlisti-takkar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 0.08em;
  margin: 0.02em 0;
}

.flettíngarlisti-takkar button {
  font-size: 0.08em;
  width: fit-content;
}

.flettíngarlisti-takkar-2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 0.04em;
  margin: 0.01em 0;
}

.flettíngarlisti-takkar-2 button {
  font-size: 0.04em;
  width: fit-content;
}

.flettíngarlisti-takkar-1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 0.06em;
  margin: 0.015em 0;
}

.flettíngarlisti-takkar-1 button {
  font-size: 0.06em;
  width: fit-content;
}

@media (max-aspect-ratio: 1) {


}