.útfillíngartala-aðal {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  font-size: 7em;
  
}

.útfillíngartala-lesmál {
  margin-left: 0.2em;
  margin-bottom: 0.2em;
}

.útfillíngartala-inntak-kassi {
  display: flex;
  margin-left: 0.5em;
}

.útfillíngartala-inntak {
  color: white;
  font-size: 1.2em;
  font-family: 'Times New Roman', Times, serif;
  width: fit-content;
  margin: 0 0.2em;
  text-align: center;
}

.útfillíngartala-inntak::-webkit-outer-spin-button,
.útfillíngartala-inntak::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* 
.útfillíngartala-örvarjóns {
  cursor: pointer;
  font-weight: bold;
  transition-duration: 0.4s;
  font-size: 1.1em;
  color: white;
  text-shadow: 0 0 3px black;
}

.útfillíngartala-örvarjóns:active {
  color: black;
} */


.útfillíngartala-skrániðurstöðu-ör {
  font-size: 1.1em;
  cursor: pointer;
  margin: 0 0.25em;
}

.útfillingartala-greiðsla {
  border: 2px solid white; 
  border-radius: 100%;
}