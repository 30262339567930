.staðfestatíma-umgjörð {
  position: relative;
  width: 100%;
}

.staðfestatíma-dagsetníng {
  font-size: 4.5em;
  margin-top: 2em;
  text-align: center;
}

.staðfestatíma-tími {
  font-size: 6em;
  margin-top: 0.8em;
}

.staðfestatíma-staðfestatíma {
  margin-top: 1em;
  margin-bottom: 0.5em;
  font-size: 4em;
}

.staðfestatíma-breitaumtíma {
  position: absolute;
  top: 0.3em;
  left: 0.2em;
  font-size: 1.5em;
  width: 6em;
  text-align: center;
}

@media (max-aspect-ratio: 6/5) {
  .staðfestatíma-breitaumtíma {
    font-size: 2.3em;
    width: 5.5em;
  }

  .staðfestatíma-staðfestatíma {
    font-size: 7em;
  }
}