

.upplisíngasíða-texti {
  font-size: 2em;
  width: 80%;
  margin: 1em 0;
  padding: 0.5em;
  background-color: rgba(0, 0, 0, .25);
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);
  border-radius: 0.4em;
}


@media (max-aspect-ratio: 8/5) {
  .upplisíngasíða-texti {
    font-size: 1.7em;
  }

}