.opnirleikir-valkostir {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
}

.opnirleikir-valkostur button {
  width: fit-content;
  font-size: 1.6em;
  margin: 0.5em 0.2em;
  height: fit-content;
  max-width: 90%;
}

.opnirleikir-leita-firirsögn {
  font-size: 3em;
}

.opnirleikir-valkostir-listi {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 90%;
  height: calc(100vh - 14em);
  min-height: 14em;
  padding-left: 0.5em;
  position: relative;
}

.opnirleikir-tímar {
  font-size: 1.4em;
  margin: 0.7em;
}

.opnirleikir-firirsögn-tímar {
  font-size: 1.6em;
  margin: 0.2em;
}

.opnirleikir-útfillíng {
  font-size: 0.2em;
  max-width: 90%;
  margin-left: 0.8em;
}

.opnirleikir-valkostur {
  margin: 2em 0;
}

.opnirleikir-leita {
  font-size: 2em;
  margin-top: 0.3em;
}




@media (max-aspect-ratio: 6/5) {
  .opnirleikir-valkostir {
    width: 100%;
  }

  .opnirleikir-leita-firirsögn {
    font-size: 6em;
  }

  .opnirleikir-valkostir-listi {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 95%;
    height: fit-content;
  }

  .opnirleikir-valkostur {
    width: 31%;
    font-size: 2.4em;
    max-height: 18em;
    overflow-y: auto;
    background-color: rgba(0,0,0,0.1);
  }

  .opnirleikir-valkostur-leingri {
    width: fit-content;
    min-width: 31%;
  }

  .opnirleikir-leita {
    font-size: 6em;
  }

}