.aðalheim-aðal {
  
  width: 80%;
  background-color: transparent;
  display: flex;
  font-size: 1rem;
}

.aðalheim-vinstri {
  min-height: 100%;
  width: 43%;
}

.aðalheim-listaval {
  width: 100%;
}

/* .aðalheim-næstileikurkassi {
  width: 30rem;
  font-size: 0.33rem;
  height: 17rem;
  margin: 1rem 0;
} */

.aðalheim-takkar {
  width: 90%;
  justify-content: space-between;
  margin-top: 1em;
  /* display: flex;
  flex-direction: column;
  align-items: center; */
}

.aðalheim-takkakassi {
  margin: 0.5em 0;
  font-size: 2.5em;
}

.aðalheim-leikir {
  width: 57%;
  height: 100%;
}

.aðalheim-firirsögn-lista {
  font-size: 2.5em;
  margin: 0.5rem;
}


/* .aðalheim-leikjalistakassi {
  width: 57%;
  height: calc(100vh - 7rem); 
  min-height: 15rem;
  font-size: 0.38rem;
  margin-top: 2em;
  margin-bottom: 0.5rem;
} */

.aðalheim-umgjörð-listar {
  font-size: 42em;
  height: calc(100vh - 0.11em);
  min-height: 0.7em;
}

@media (max-aspect-ratio: 6/5) {
  /*listi og leikjatakkar hlið við hlið*/
  .aðalheim-aðal {
    width: 100%;
    font-size: 1.3em;
  }

}

@media (max-aspect-ratio: 9/10) {
  /*Allt lóðrétt*/
  .aðalheim-aðal {
    flex-direction: column;
  }

  .aðalheim-vinstri {
    width: 100%;
    font-size: 1.8em;
    flex-direction: column-reverse;
  }

  .aðalheim-leikir {
    width: 100%;
    font-size: 1.6em;
    margin-top: 2em;
    
  }

  .aðalheim-umgjörð-listar {
    font-size: 42em;
    height: auto;
    min-height: 0.7em;
  }
}