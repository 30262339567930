.búatilót-velja {
  height: 20em;
  position: relative;
  width: 60%;
  margin-top: 1.5em;
}

@media (max-aspect-ratio: 6/5) {
  
  .búatilót-velja {
    font-size: 1.5em;
    width: 80%;
  }
}