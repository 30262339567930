
.villaoghleður-heilsíða {
  font-size: 5rem;
  margin-top: 8rem;
  padding: 0.5rem;
  border-radius: 0.4rem;
  max-width: 90%;
  text-align: center;
}

.villaoghleður-villa {
  background-image: linear-gradient(120deg, #e0826a, #ea4715);
}

.villaoghleður-hleður {
  background-image: linear-gradient(120deg, #ea9438, #d99d07);
}

.villaoghleður-tókst {
  background-image: linear-gradient(120deg, #22710e, #87b762);
}

.villaoghleður-villa, .villaoghleður-hleður, .villaoghleður-tókst {
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);
  padding: 0.1em;
  border-radius: 0.1em;
  text-align: center;
}

