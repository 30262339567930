.hahúh-aðal {
  cursor: pointer;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 7em;
}

.hahúh-lesmál {
  margin-left: 0.2em;
  margin-bottom: 0.2em;
}

.hahúh-inntak {
  height: 0;
  width: 0;
  opacity: 0;
  position: absolute;
  cursor: pointer;
}

.hahúh-hak {
  margin-left: 0.4em;
  height: 1.6em;
  width: 1.6em;
  background-color: rgba(255, 255, 255, 0.3);
}

.hahúh-inntak:checked~.hahúh-hak {
  background-color: rgba(0, 0, 0, 0.4);
}

.hahúh-hak:after {
  content: "";
  position: relative;
  top: 0.16em;
  left: 0.45em;
  width: 0.45em;
  height: 0.85em;
  border: solid white;
  border-width: 0 0.25em 0.25em 0;
  display: none;
  transform: rotate(40deg);
}

.hahúh-inntak:checked~.hahúh-hak:after {
  display: block;
}
