.veljalið-listi-ivir-lið {
  font-size: 50rem;
  height: calc(100dvh - 10rem);
}

.veljalið-firirsögn-smeiju {
  font-size: 3rem;
  margin: 1rem 0;
}

@media (max-aspect-ratio: 6/5) {
  .veljalið-listi-ivir-lið {
    font-size: 90rem;
    height: calc(100dvh - 12rem);
  }
  
  .veljalið-firirsögn-smeiju {
    font-size: 5rem;
    margin: 2rem 0;
  }

}