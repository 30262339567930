.tilkinníngarsmeija-umgjörð {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.tilkinníngarsmeija-skilaboð {
  font-size: 5em;
  margin: 0.4em;
  text-align: center;
  max-width: 90%;
}

.tilkinníngarsmeija-takki {
  width: fit-content;
  font-size: 3em;
}

@media (max-aspect-ratio: 1) {
  .tilkinníngarsmeija-skilaboð {
    font-size: 8em;
  }
  
  .tilkinníngarsmeija-takki {
    width: fit-content;
    font-size: 6em;
  }

}
