.veljatfirir-undirfirirsögn {
  font-size: 3em;
  margin: 1em 0 0.4em 0;
}

.veljatfirir-tímabiltakkar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 50%;
  margin: 1em 0;
}

.veljatfirir-tímabiltakkar button {
  width: fit-content;
  font-size: 2em;
}

.veljatfirir-dagar {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2em 0 4em 0;
}

.veljatfirir-dagar button, .veljatfirir-dagur, .veljatfirir-dagurtil {
  font-size: 2.2em;
  margin: 1em 0;
}

.veljatfirir-tímabilsdagar button {
  font-size: 3em;
  width: fit-content;
  height: fit-content;
  margin: 0.6em;
}


@media (max-aspect-ratio: 6/5) {

  .veljatfirir-tímabiltakkar {
    font-size: 2rem;
  }

  .veljatfirir-tímabilsdagar button {
    font-size: 5em;
  }

  .veljatfirir-dagar button, .veljatfirir-dagur, .veljatfirir-dagurtil {
    font-size: 4em;
  }
}