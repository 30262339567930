.veljatíma-umgjörð {
  position: relative;
  width: 100%;
}

.veljatíma-dagsetníng-lesmál {
  font-size: 3em;
  margin: 0.3em;
}

.veljatíma-breitaumdag {
  position: absolute;
  top: 0.3em;
  left: 0.4em;
  font-size: 1.5em;
  width: 6em;
  text-align: center;
}

.veljatíma-hleður {
  font-size: 6em;
  text-align: center;
  max-width: 10em;
  margin-top: 30%;
}

.veljatíma-villa {
  font-size: 4em;
  text-align: center;
  max-width: 15em;
}

.veljatíma-skilaboð {
  font-size: 1.5em;
  margin: 0.5em 0;
}

.veljatíma-tímardags {
  width: 60em;
  height: calc(100vh - 9em);
  overflow-y: auto;
  scroll-padding-top: 20em;
  min-height: 20em;
}

.veljatíma-tímabil {
  width: 2.5em;
  height: 2em;
  font-size: 2.8em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5em;
  background-color: rgba(255,255,255,0.3);
  cursor: pointer;
  transition-duration: 0.4s;
}

.veljatíma-tímabil:hover {
  scale: 1.1;
  background-color: rgba(0,0,0,0.3);
}

.veljatíma-upptekinn {
  background-image: linear-gradient(120deg, #e0826a, #ea4715);
  cursor: not-allowed;
}

.veljatíma-upptekinn:hover {
  scale: 1;
  background-image: linear-gradient(120deg, #e0826a, #ea4715);
}

.veljatíma-skráður {
  background-image: linear-gradient(120deg, #ea9438, #d99d07);
}

.veljatíma-næstidagur {
  font-size: 2em;
  margin-top: 1em;
  margin-bottom: 0.5em;
}

.veljatíma-valinntími {
  background-color: rgba(0,0,0,0.4);
}

.veljatíma-veljanleigir {
  scale: 1.1;
}

.veljatíma-veljanleigir:hover {
  scale: 1.2;
}

.veljatíma-óveljanleigir {
  background-color: transparent;
  visibility: hidden;
}

@media (max-aspect-ratio: 6/5) {
  .veljatíma-breitaumdag {
    font-size: 2em;
    width: 5.5em;
  }

  .veljatíma-tímardags {
    margin-top: 2em;
    height: calc(100vh - 10em);
  }

  .veljatíma-skilaboð {
    font-size: 1.9em;
  }
}

