.völlur-aðal {
  display: flex;
  position: relative;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding-bottom: 10em;
}

.völlur-firirsögn {
  font-size: 4em;
  margin: 0.3em 0;
}

.völlur-undirfirirsögn {
  font-size: 2em;
  margin: 0.8em 0;
}

.völlur-mindogupplísíngar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  height: fit-content;
}

.völlur-mind {
  width: 66%;
  height: fit-content;
  border-radius: 5%;
  margin-bottom: 2em;
}

.völlur-allskonarupplísíngar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 34%;
  margin-bottom: 3em;
}

.völlur-upplísíngar {
  font-size: 2em;
  margin: 0.2em 0;
}

.völlur-leikvángur {
  border: 0.5em solid white;
  margin-top: 3em;
  width: 6em;
  height: 6em;
}

.völlur-leikvángur-stærð {
  font-size: 1.5em;
}

.völlur-breidd {
  position: relative;
  top: -1.5em;
  left: calc(50% - 2em);
  width: fit-content;
}

.völlur-leingd {
  position: relative;
  writing-mode: vertical-lr;
  transform: scale(-1);
  left: -1.5em;
  top: calc(50% - 3em);
}

.völlur-um {
  font-size: 3em;
  margin: 1em 0 0em 0;
}

/* .völlur-um-texti {
  width: 90%;
} */

.völlur-undirvellir {
  font-size: 0.4em;
  max-width: 80%;
}

.völlur-leikhættir-firirsögn {
  font-size: 3em;
  margin: 1em 0 0.5em 0;
}

.völlur-leikhættir {
  width: 70%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.völlur-leikháttur {
  width: 25%;
  font-size: 1.6em;
  text-align: center;
  margin: 0.5em 0;
  padding: 0.5em;
  background-color: rgba(255,255,255,0.1);
}

.völlur-hlekkir {
  display: flex;
  width: 60%;
  justify-content: space-between;
  margin: 5em;
  padding-bottom: 3em;
}


.völlur-hlekkir a {
  text-decoration: none;
  font-size: 1.5em;
  cursor: pointer;
  transition-duration: 0.4s;
  background-color: rgba(0, 0, 0, .25);
  box-shadow: 0 0 0.5em 0 rgba(0, 0, 0, .2);
  padding: 0.2em;
  max-width: 40%;
  text-align: center;
}

.völlur-hlekkir a:hover {
  transform: scale(1.1);
}

.völlur-búatilleik-takki button {
  font-size: 2.2em;
}

.völlur-sjádagskrá-takki {
  margin-top: 3em;
  margin-bottom: 1em;
  font-size: 2em;
  padding-bottom: 2em;

}

.völlur-tími-firirsögn {
  font-size: 3em;
}

.völlur-umgjörðtíma {
  margin: 1em 0;
  box-shadow: 0 0 1em 0 rgba(0, 0, 0, .2);
  width: 80%;
  display: flex;
  justify-content: center;
  font-size: 0.8em;
  padding-bottom: 2em;
}

@media (max-aspect-ratio: 1) {

  .völlur-aðal {
    font-size: 2.4em;
  }

  .völlur-firirsögn {
    font-size: 3em;
  }
  
  .völlur-undirfirirsögn {
    font-size: 1.2em;
  }


  .völlur-mind {
    width: 90%;
  }
  
  .völlur-allskonarupplísíngar {
    width: 90%;
    margin-top: 1em;
  }

  .völlur-leikhættir {
    width: 90%;
  }
  
  .völlur-leikháttur {
    width: 35%;
  }

  .völlur-hlekkir {
    width: 90%;
  }

  .völlur-umgjörðtíma {
    width: 90%;
    font-size: 0.4em;
  }

}