.forsíðutakkar-leikjatakkar {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 1em 0;
}

.forsíðutakkar-miðjaðurleikjatakki {
  justify-content: center;
}

.forsíðutakkar-leikjatakkakassi {
  font-size: 1.5em;
  width: 45%;
}

.forsíðutakkar-leikjatakkakassi-minni {
  font-size: 1.2em;
  width: 45%;
  min-height: 2em;
}

@media (max-aspect-ratio: 9/10) {
  .forsíðutakkar-leikjatakkar {
    margin: 0.7em 0;
  }

  .forsíðutakkar-leikjatakkakassi {
    font-size: 1.6em;
  }

  .forsíðutakkar-leikjatakkakassi-minni {
    font-size: 1.4em;
  }
}
