.leppurgutlari-máti {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, .15);
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);
  padding: 5em;
  border-radius: 5%;
}

.leppurgutlari-myndognafn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:space-between;
  height: 100%;
  transition-duration: 0.4s;
  cursor: pointer;
}

.leppurgutlari-myndognafn:hover {
  scale: 1.03;
}

.leppurgutlari-myndognafn:active {
  scale: 1.3;
}

.leppurgutlari-takkakassi {
  font-size: 12em;
  width: 6.2em;
  margin: 0.3em;
}

.leppurgutlari-mynd-af-máta {
  max-height: 85em;
  border-radius: 100%;
  max-width: 90%;  
}

.leppurgutlari-nafn-máta {
  font-size: 14em;
  text-align: center;
  margin-top: 0.35em;
}


.leppurgutlari-nafn-máta:active {
  color: black
}

.leppurgutlari-jánka-mátabón-kassi {
  font-size: 10em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  justify-self: flex-end;
}

.leppurgutlari-svar-miðlara {
  font-size: 10em;
  text-align: center;
  background-color: rgba(76, 83, 173, 0.3);
  border-radius: 5%;
  padding: 0.3em;
}

