

.skrágreiðslu-útfillíng {
  width: fit-content;
}

.skrágreiðslu-tími {
  margin-bottom: 2em;
}

.skrágreiðslu-verð {
  align-self: flex-start;
  margin-bottom: 0em;
  margin-left: 1em;
}

