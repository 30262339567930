.leitveljavöll-valmöguleikar {
  display: flex;
  flex-direction: column;
  width: 29%;/* 20rem; */
  margin-top: 0.5em;
}

.leitveljavöll-leit-firirsögn {
  font-size: 2em;
  margin: 0.5em 0;
  align-self: center;
}

.leitveljavöll-texti {
  font-size: 1.5em;
  margin: 0.5em 0;
  align-self: center;
}

.leitveljavöll-valmöguleikar-listi {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 14em);
  min-height: 20em;
  overflow-y: auto;
  position: relative;
}

.leitveljavöll-leit-dálkur {
  height: fit-content;
  font-size: 0.18em;
  margin-left: 5.5em;
  margin-bottom: 5em;
  padding-bottom: 1em;
}

.leitveljavöll-leit-nafn {
  width: 85%;
  font-size: 1em;
  margin-left: 5em;
  margin-top: 2.5em;
  margin-bottom: 5em;
}

.leitveljavöll-þorp-nafn {
  font-size: 8em;
  margin: 0.4em 0 0 0.6em;
}

.leitveljavöll-þorp-takki {
  font-size: 7em;
  width: fit-content;
  margin: 0.5em;
}


.leitveljavöll-þorp-valið {
  font-size: 6.6em;
  margin: 0.3em;
}


.leitveljavöll-leitafvellival-kassi {
  font-size: 2.5em;
  align-self: center;
  margin: 0.3em 0;
}


@media (max-aspect-ratio: 6/5) {

  .leitveljavöll-valmöguleikar-listi {
    height: calc(100vh - 11em);   
  }

}

@media (max-aspect-ratio: 8/10) {
  
  .leitveljavöll-valmöguleikar {
    width: 100%;/* 20rem; */
    margin-top: 0.5em;
    margin-bottom: 1em;
  }

  .leitveljavöll-leit-firirsögn {
    font-size: 4em;
  }

  .leitveljavöll-texti {
    font-size: 3em;
  }

  .leitveljavöll-valmöguleikar-listi {
    flex-direction: row;
    flex-wrap: wrap;
    height: auto;
  }

  .leitveljavöll-leit-nafn {
    width: 95%;
    margin-left: 3%;
    margin-top: 0.1em;
  }

  .leitveljavöll-leit-dálkur {
    font-size: 0.34em;
    max-height: 70em;
    width: 30%;
    overflow-y: auto;
    background-color: rgba(0,0,0,0.1);
  }

  .leitveljavöll-leitafvellival-kassi {
    font-size: 3.5em;
  }

}



/*
EKKI EIÐA STRAX
.leitveljavöll-leit-bær {
  height: 20rem;
  width: fit-content;
  font-size: 0.18rem;
  margin-left: 1rem;
  margin-bottom: 2rem;
}

.leitveljavöll-leit-kvervi {
  height: 4rem;
  width: fit-content;
  font-size: 0.18rem;
  margin-left: 1rem;
  margin-bottom: 1rem;
} */