.skráinn-aðal {
  width: 90rem;
}

.skráinn-firirsögn {
  margin: 1rem;
  font-size: 3.5rem;
  text-align: center;
}

.skráinn-inntök {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.skráinn-inntak-lesmál {
  width: 40%;
  font-size: 0.4rem;
  margin: 3rem 5%;
}

.skráinn-staðfestaogvilla {
  width: 100%;
}

.skráinn-staðfestaogvilla-takki {
  font-size: 4rem;
  margin: 1rem 0;
}

.skráinn-gleimtlikilorð-senda {
  font-size: 2rem;
  margin: 1rem 0;
  text-align: center;
}

.skráinn-gleimtlikilorð button {
  margin-top: 2rem;
  font-size: 1.4rem;
}

@media (max-aspect-ratio: 6/5) {
  .skráinn-firirsögn {
    font-size: 5.5rem;
  }

  .skráinn-inntak-lesmál {
    width: 80%;
    font-size: 1rem;
  }

  .skráinn-staðfestaogvilla-takki {
    font-size: 7rem;
  }

  .skráinn-gleimtlikilorð button {
    margin: 5rem 0;
    font-size: 3rem;
  }

  .skráinn-gleimtlikilorð-senda {
    font-size: 4rem;
  }
}