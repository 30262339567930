

.búatilfirir-veljavöll {
  height: 28em;
  position: relative;
  width: 50%;
  margin-top: 2em;
}


@media (max-aspect-ratio: 6/5) {
  .búatilfirir-veljavöll {
    font-size: 1.5em;
    width: 80%;
  }
}



