.opnirleikir-aðal {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  min-height: 100vh;
  width: 100%;
}


.opnirleikir-firirsögn {
  font-size: 3em;
  margin: 0.4em 0;
}

.opnirleikir-umgjörð {
  display: flex;
  width: 100%;
}

.opnirleikir-listarafleikjum {
  display: flex;
  width: 70%;
  justify-content: space-around;
}

.opnirleikir-listi-umgjörð {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.opnirleikir-firirsagnir {
  font-size: 2em;
  margin: 0.5em;
  height: fit-content;
}

.opnirleikir-firirsagnir-stærri {
  font-size: 3em;
}

.opnirleikir-minnilisti {
  font-size: 30em;
  height: calc(100vh - 0.38em); 
  min-height: 0.50em;
  margin-top: 0.01em;
}

.opnirleikir-stærrilisti {
  font-size: 65em;
  height: calc(100vh - 0.2em); 
  min-height: 0.23em;
  margin-top: 0.002em;
}

.opnirleikir-stærrilisti2 {
  font-size: 65em;
  height: calc(100vh - 0.1em); 
  min-height: 0.23em;
  margin-top: 0.002em;
}

.opnirleikir-fleirileikir-takkar {
  display: flex;
  font-size: 1.8em;
}

.opnirleikir-fleirileikir-takkar button {
  margin: 0.1em 0.7em;
  width: fit-content;
  height: fit-content;
}

@media (max-aspect-ratio: 6/5) {
  .opnirleikir-firirsögn {
    font-size: 6em;
  }

  .opnirleikir-umgjörð {
    flex-direction: column;
  }

  .opnirleikir-listarafleikjum {
    width: 100%;
    font-size: 1.38rem;
    margin-top: 2em;
  }

  .opnirleikir-firirsagnir {
    font-size: 4em;
  }

  .opnirleikir-minnilisti {
    font-size: 36em;
    height: 80vh; 
  }
  
  .opnirleikir-stærrilisti, .opnirleikir-stærrilisti2 {
    height: 80vh; 
  }

  .opnirleikir-fleirileikir-takkar {
    font-size: 4em;
  }
  

}
