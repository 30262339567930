.spjallkassi-spjallkassi {
  max-width: 70%;
}

.spjallkassi-hægri {
  align-self: flex-end;
  margin: 0.2em 0.5em 0.2em 0;
}

.spjallkassi-vinstri {
  align-self: flex-start;
  margin: 0.2em 0 0.2em 0.5em;
}

.spjallkassi-nafnspjallanda {
  font-size: 1em;
  cursor: pointer;
}

.spjallkassi-lesmál {
  font-size: 1.2em;
  padding: 0.2em;
  background-color: rgba(255,255,255,0.4);
  border-radius: 0.2em;
  overflow-y: auto;
  overflow-wrap: break-word;
}