.spjall-listiskilaboða {
  display: flex;
  flex-direction: column-reverse;
  overflow-y: auto;
  margin: 0.5em 0 0.8em 0;
  width: 100%;
  height: calc(100% - 3.5em);
  background-color: rgba(0,0,0,0.1)
}

.spjall-listiskilaboða-sækjameira {
  align-self: center;
  font-size: 1.5em;
  width: fit-content;
  max-width: 95%;
  margin: 2em 0;
}

.spjall-inntakskassi {
  height: 2em;
  width: 22em;
  font-size: 1em;
  color: black;
  display: flex;
}

.spjall-inntak {
  text-shadow: none;
  color: white;
  border-radius: 0;
  border: none;
  padding-left: 0.2em;
  font-size: 1.2em;
  background-color: rgba(0, 0, 0, 0.5);
  font-family: 'Times New Roman', Times, serif;
  height: 1.5em;
}

.spjall-inntak:focus {
  outline: none;
}

.spjall-inntakskassi button {
  font-size: 1.3em;
  margin-left: 0.8em;
}

.spjall-teingingarvilla-titill {
  font-size: 4em;
  margin: 0.5em 0;
}

.spjall-teingingarvilla-texti {
  font-size: 1.5em;
  max-width: 90%;
  text-align: center;
}
