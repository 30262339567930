.takki2 {
  width: 100%;
  height: 100%;
  background-color: var(--takkalitur);
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .4);
  border: 0.1em solid var(--dökkandstæður);
  text-decoration: none;
  font-size: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition-duration: 0.4s;
}

.takki2:hover {
  transform: scale(1.1);
}

.takki2:active {
  background-color: black;
}