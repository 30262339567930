.firirætlun-takkar {
  display: flex;
  justify-content: space-between;
  width: 50%;
}

.firirætlun-takkar button {
  width: fit-content;
}

@media (max-aspect-ratio: 6/5) {
  .firirætlun-takkar {
    width: 80%;
  }

}