

.spjallkassiárn-nafnspjallanda {
  font-size: 2em;
}

.spjallkassiárn-lesmál {
  font-size: 2.2em;
  padding: 0.2em;
  background-color: rgba(255,255,255,0.4);
  border-radius: 0.2em;
  overflow-y: auto;

}