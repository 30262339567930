
.veljadag-firirsögn-mánuðir {
  font-size: 3em;
  margin: 0.5em 0;
}

.veljadag-listiivirdaga {
  width: 62em;
  height: calc(100vh - 10.5em);
  overflow-y: auto;
  min-height: 20em;
}

.veljadag-nafnmánaðar {
  font-size: 3em;
  margin: 0.5em 0;
}

.veljadag-nafnvikudaga {
  display: flex;
  width: 56em;
}

.veljadag-nafnvikudags {
  height: 0.75em;
  font-size: 2em;
  width: 3em;
  margin: 0.5em;
  text-align: center;
}

.veljadag-dagarmánaðar {
  display: flex;
  flex-wrap: wrap;
  width: 56em;
}

.veljadag-dagur {
  /*af einkverjum ástæðum sínir firefox í síma ekki rétt ef það er 3em*/
  width: 2.999em;
  margin: 0.5em;
  height: 2em;
  font-size: 2em;
  background-color: rgba(255,255,255,0.3);
}

.veljadag-dagur:hover {
  background-color: rgba(0,0,0,0.3);
}

.veljadag-búinn {
  background-image: linear-gradient(120deg, #e0826a, #ea4715);
  cursor: not-allowed;
}

.veljadag-búinn:hover {
  background-image: linear-gradient(120deg, #e0826a, #ea4715);
}

@media (max-aspect-ratio: 6/5) {

  .veljadag-firirsögn-mánuðir {
    font-size: 5em;
  }

  .veljadag-listiivirdaga {
    width: 69em;
  }

  .veljadag-nafnvikudaga {
    font-size: 1.125em;
  }

  .veljadag-dagarmánaðar {
    font-size: 1.125em;
  }
}