.svaraleikjanautabón-aðal {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.svaraleikjanautabón-firirsögn {
  font-size: 3em;
  margin: 0.4em;
}

.svaraleikjanautabón-nafn {
  font-size: 3em;
  margin: 0.3em;
  text-align: center;
}

.svaraleikjanautabón-mind {
  width: 30%;
  border-radius: 100%;
  margin-bottom: 2em;
}

.svaraleikjanautabón-takki {
  font-size: 3em;
  margin: 0.5em;
}

.svaraleikjanautabón-takkakassi {
  display: flex;
}

@media (max-aspect-ratio: 1) {
  .svaraleikjanautabón-aðal {
    font-size: 2em;
  }

  .svaraleikjanautabón-mind {
    width: 60%;
  }

}