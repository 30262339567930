.hittogþettaGL-aðal {
  width: 100%;
  /* display: flex;
  flex-direction: column;
  align-items: center; */
}

.hittogþettaGL-firirsögn {
  font-size: 2.8em;
  margin-top: 0.3em;
  margin-bottom: 0.6em;
}

.hittogþettaGL-umgjörð-eiginleika {
  width: 98%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}



.hittogþettaGL-kassi-eiginleika {
  width: 45%;
  margin-bottom: 2%;
  background-color: rgba(0,0,0,0.1);
}

.hittogþettaGL-nafn-eiginleika {
  font-size: 2em;
}

.hittogþettaGL-eiginleiki {
  font-size: 1.8em;
  margin: 0.3em 0 0 0.9em;
}

.hittogþettaGL-ervöllurlaus {
  width: fit-content;
  font-size: 1.4em;
  margin: 0.8em;
}

.hittogþettaGL-neðritakkar {
  display: flex;
  flex-wrap: wrap;
  width: 95%;
  justify-content: space-around;
}

.hittogþettaGL-neðritakki {
  font-size: 2.5em;
  margin: 0.5em 0.2em;
}

@media (max-aspect-ratio: 1) {
  .hittogþettaGL-firirsögn {
    font-size: 5em;
  }

  .hittogþettaGL-kassi-eiginleika {
    width: 90%;
    margin-bottom: 4%;
    font-size: 3em;
  }


  .hittogþettaGL-neðritakkar {
    flex-direction: column;
    font-size: 3em;
    margin-bottom: 3em;
    margin: 0.5em 0 3em 0;
    width: 85%;
  }
}

