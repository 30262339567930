.veljavöll-aðal {
  width: 98%;
  justify-content: space-between;
  font-size: 1rem;
}

.veljavöll-sérveljaútfillíng-umgjörð {
  width: 69%;/* 47rem; */
  height: 100%;
  position: relative;
}

.veljavöll-sérvelja-nafn {
  margin-bottom: 4em;
  width: 80%;
  font-size: 0.5em;
}

.veljavöll-leitafvellival-kassi {
  font-size: 3em;
  align-self: center;
  margin: 0.3em 0;
}

.veljavöll-firirsögnoglisti {
  width: 69%;/* 47rem; */
  position: relative;
}

.veljavöll-faraíkort-kassi {
  position:absolute;
  right: 1.5em;
  font-size: 1.5em;
}

.veljavöll-sérvelja-kassi {
  position:absolute;
  left: 0.5em;
  top: 0.2em;
  font-size: 1.5em;
}

.veljavöll-firirsögn {
  font-size: 2em;
  margin: 0.25em 0;
}
.veljavöll-listiafvöllum-kassi {
  font-size: 46em;
  height: calc(100vh - 0.19em);
  min-height: 0.52em;
}

/*
EKKI EIÐA ÞESSU STRAX
.veljavöll-takkar {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 1rem;
}

.veljavöll-takkar button {
  width: 30%;
  font-size: 2rem;
}

.veljavöll_leitskilaðieingu {
  font-size: 2rem;
  margin-top: 1rem;
} */

@media (max-aspect-ratio: 6/5) {

  .veljavöll-aðal {
    font-size: 1.45rem;
  }

  .veljavöll-listiafvöllum-kassi {
    height: calc(100vh - 0.13em);
  }

  .veljavöll-faraíkort-kassi {
    right: 4em;
  }

}

@media (max-aspect-ratio: 8/10) {

  .veljavöll-aðal {
    flex-direction: column;
  }

  .veljavöll-sérveljaútfillíng-umgjörð {
    width: 100%;
    height: 30em;
  }

  .veljavöll-faraíkort-kassi {
    right: 1em;
    font-size: 2em;
  }
  
  .veljavöll-sérvelja-kassi {
    font-size: 2em;
  }

  .veljavöll-firirsögn {
    font-size: 4em;
  }

  .veljavöll-firirsögnoglisti {
    width: 100%;
    font-size: 1.3em;
    margin-top: 2em;
  }

  .veljavöll-listiafvöllum-kassi {
    font-size: 50em;
    height: auto;
    min-height: auto;
  }
}