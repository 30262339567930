.liðmemmaðir-firirsögn {
  font-size: 3em;
  margin: 0.4em;
}

.liðmemmaðir-sjáliðsgögn-takki {
  font-size: 1.5em;
  width: fit-content;
  height: fit-content;
}

.liðmemmaðir-umgjörð {
  width: 100%;
  display: flex;
}

.liðmemmaðir-listiliðsmanna-umgjörð {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.liðmemmaðir-minnifirirsögn {
  font-size: 2em;
  margin: 0.5em 0;
}

.liðmemmaðir-listiliðsmanna-listi {
  font-size: 33em;
  background-color: rgba(0, 0, 0, 0.1);
}

@media (max-aspect-ratio: 6/5) {
  .liðmemmaðir-firirsögn {
    font-size: 5em;
  }
  
  .liðmemmaðir-sjáliðsgögn-takki {
    font-size: 3em;
  }

  .liðmemmaðir-minnifirirsögn {
    font-size: 3.2em;
  }

}