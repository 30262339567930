.leppurlið-lið {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.leppurlið-myndognafn {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition-duration: 0.4s;
  text-decoration: none;
}

.leppurlið-myndognafn:hover {
  scale: 1.03;
}

.leppurlið-myndognafn:active {
  scale: 1.3;
}

.leppurlið-takkakassi {
  font-size: 8em;
  width: 9.5em;
  margin: 0.03em 0;
}

.leppurlið-mynd-af-liði {
  max-width: 100%;
  border-radius: 100%;
  max-height: 50em;
}

.leppurlið-nafn-liðs {
  font-size: 10em;
  text-align: center;
  margin-top: 0.3em;
}

.leppurlið-memma-lið-kassi {
  height: fit-content;
  width: 80%;
  font-size: 10em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.leppurlið-svar-miðlara {
  font-size: 6em;
  margin: 0.5em 0;
  width: 80%;
  text-align: center;
  background-color: rgba(39, 80, 107, 0.3);
  border-radius: 5%;
  padding: 0.3em;
}

.leppurlið-liðsgögn-umgjörð {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
}

.leppurlið-mindísmeiju {
  width: 40rem;
  border-radius: 100%;
}

.leppurlið-lísíngáliði {
  font-size: 3rem;
  margin: 1.5rem;
  width: 50rem;
  text-align: center;
}

.leppurlið-vinaliðastaða {
  font-size: 7em;
}