.tölvuskeitissíða-aðal {
  flex-direction: column;
  align-items: center;
}

.tölvuskeitissíða-firirsögn {
  font-size: 3em;
  max-width: 80%;
  margin: 0.4em;
  text-align: center;
}

.tölvuskeitissíða-lítilskilaboð {
  font-size: 1em;
  margin-bottom: 1em;
  text-align: center;
  max-width: 90%;
}

.tölvuskeitissíða-inntak {
  width: 100em;
  font-size: 0.3em;
  margin: 5em;
}

.tölvuskeitissíða-stærritakki {
  font-size: 5em;
  max-width: 90%;
  margin: 0.2em;
  text-align: center;
}

.tölvuskeitissíða-minnitakki {
  font-size: 2.5em;
  width: fit-content;
  margin: 0.4em;
  max-width: 90%;
  text-align: center;
}

.tölvuskeitissíða-skilaboðmiðlara {
  font-size: 2em;
  max-width: 80%;
  text-align: center;
}



@media (max-aspect-ratio: 8/5) {
  .tölvuskeitissíða-aðal {
    font-size:1.9rem;
  }

}

@media (max-aspect-ratio: 1) {
  .tölvuskeitissíða-aðal {
    font-size: 2.2rem;
  }

}


@media (max-aspect-ratio: 8/10) {
  .tölvuskeitissíða-aðal {
    font-size: 3rem;
  }

  .tölvuskeitissíða-stærritakki {
    font-size: 3em;
  }

}